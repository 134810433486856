body,
html,
#root {
  height: 100%;
}

.App {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Optima';
  font-size: 36px;
  }
.Contact{
    color: black;
    font-size: 22px;
    
  }